<template>
  <v-app>
    <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-alert v-model="success" type="success" dismissible>
        {{dialogText}}
      </v-alert>
      <v-alert v-model="error" type="error" dismissible>
        {{dialogText}}
      </v-alert>
      <v-card>
        <v-card-title class="indigo white--text" style="font-size:30px">
          ユーザ管理
        </v-card-title>
        <v-row class="pa-4" justify="space-between">
        
          <!-- 画面左部ツリー -->
          <v-col cols="5">
            <v-text-field
              v-model="treeSearch"
              label="Search"
              flat
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
              style="margin:8px 38px"
            ></v-text-field>
            <v-treeview
              activatable
              :items="items"
              :active.sync="active"
              :search="treeSearch"
              :filter="filter"
              style="font-size:16px"
            >
              <template v-slot:prepend="{ item }">
                <div v-if="item.delflg===1" style="color:red;">×</div>
              </template>
            </v-treeview>
          </v-col>
          <!-- 画面左部ツリー -->
          
          <v-divider vertical></v-divider>
          
          <!-- 画面右部 -->
          <v-col class="d-flex text-center">
            <v-scroll-y-transition mode="out-in">
              <!--未選択-->
              <div
                v-if="!selected"
                class="text-h6 text--lighten-1 font-weight-light"
                style="align-self: top;"
              >
                ユーザを選択して下さい。
              </div>
              <!--選択中-->
              <v-card
                v-else
                :key="selected.id"
                class="pt-6 mx-auto"
                flat
                width="95%"
              >
                <v-text-field v-if="selected.id === 0" v-model="loginid" label="ログインID" :rules="idRules">
                </v-text-field>
                <v-text-field v-else v-model="loginid" label="ログインID" disabled>
                </v-text-field>
                
                <v-text-field v-if="selected.id === 0" v-model="password" label="パスワード" :rules="passRules">
                </v-text-field>
                <v-text-field v-else v-model="password" label="パスワード" disabled>
                </v-text-field>
                
                <v-text-field v-if="selected.id === 0" v-model="mailaddress" label="メールアドレス" :rules="mailRules">
                </v-text-field>
                <v-text-field v-else v-model="mailaddress" label="メールアドレス" disabled>
                </v-text-field>
                
                <v-radio-group row v-model="radioGroup">
                  <v-radio label="管理者" key=0 ></v-radio>
                  <!-- <v-radio label="ユーザ管理者" key=1></v-radio> -->
                  <v-radio label="ユーザ" key=2></v-radio>
                </v-radio-group>
                
                <v-card>
                  <v-card-title>
                    センサグループ選択
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    dense
                    v-model="selectgroup"
                    :headers="headers"
                    :items="sensorgroup"
                    :search="search"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    item-key="sensorgroupid"
                    show-select
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                  >
                  </v-data-table>
                </v-card>
                <v-row class="pa-4 mt-1">
                  <v-spacer></v-spacer>
                  <v-btn small style="font-size:16px" v-on:click.stop="setting()" v-bind:disabled="isSet" color="primary">設定</v-btn>
                  <v-btn v-if="this.delflg===1" small style="font-size:16px" v-on:click.stop="delDialog = true" v-bind:disabled="isSetDel" color="error">有効化</v-btn>
                  <v-btn v-else small style="font-size:16px" v-on:click.stop="delDialog = true" v-bind:disabled="isSetDel" color="error">無効化</v-btn>
                </v-row>
              </v-card>
            </v-scroll-y-transition>
          </v-col>

        </v-row>
      </v-card>
      <!--ボタン部-->
      
      <v-dialog v-model="delDialog" persistent max-width="300">
        <v-card v-if="this.delflg===1">
          <v-card-title class="headline blue-grey lighten-3">ユーザ有効化確認</v-card-title>
          <v-card-text>
            選択しているユーザを有効にしますか？
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.stop="delDialog = false">
              キャンセル
            </v-btn>
            <v-btn @click.stop="delitem()" color="error">
              有効化
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-title class="headline blue-grey lighten-3">ユーザ無効化確認</v-card-title>
          <v-card-text>
            選択しているユーザを無効化しますか？
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.stop="delDialog = false">
              キャンセル
            </v-btn>
            <v-btn @click.stop="delitem()" color="error">
              無効化
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    
    </v-form>
    </v-container>
  </v-app>
</template>

<script>
  import {signUp, globalSignOut} from '@/js/auth.js'
  
  export default {
    data: () => ({
      //表示・編集用ユーザ情報
      id:null,
      loginid:null,
      password:null,
      mailaddress:null,
      radioGroup:0,
      delflg:0,
      
      isSet:true,
      isSetDel:true,
      
      active: [],
      items: [],
      users: [],
      
      hosturl: process.env.VUE_APP_APIURL,
      
      dialogText:null,
      delDialog:false,
      
      success:false,
      error:false,
      
      valid: true,
      
      search: '',
      selectgroup: [],
      selectOldGroup: [],
      usersensorgrouplist: [],
      usersensorgroup: [],
      headers: [
        { text: '', value: 'sensorgroup_name' }
      ],
      sortBy:"sensorgroup_name",
      sortDesc:false,
      sensorgroup: [],

      treeSearch: null,
      
    }),
    computed: {
      selected () {
        if (!this.active.length) return undefined

        const activeid = this.active[0]
        var user = this.items.find(item => item.id === activeid)
        
        this.setdata(user);
        
        return user
      },
      idRules() {
        return [
          v => !!v || '必須項目です。'
        ]
      },
      passRules() {
        return [
          v => !!v || '必須項目です。'
        ]
      },
      mailRules() {
        return [
          v => /.+@.+/.test(v) || 'フォーマットが間違っています。'
        ]
      },
      filter(){
        return (item, search, textKey) => item[textKey].indexOf(search) > -1
      }
    },
    methods: {
      setting: function(){
        if(this.id === 0){
          for(var x = 0; x < this.users.length; x++){
            if( this.loginid === this.users[x].loginid){
              this.dialogText = '使用済みのログインIDは登録できません。'
              this.success = false;
              this.error = true;
              this.returnTop('smooth');
              return
            }
          }
          
          var arrayLoginId = this.loginid.split('')
          var reg = new RegExp(/[!"#$%&'()*+-.,/:;<=>?@[\\\]^_`{|}~]/g);
          for(var y = 0; y < arrayLoginId.length ; y++){
            if(isFinite(arrayLoginId[y])){
              continue
            }else if(reg.test(arrayLoginId[y])){
              continue
            }
            if(arrayLoginId[y] === arrayLoginId[y].toUpperCase()){
              this.dialogText = 'ログインIDは大文字を指定できません。小文字のみで登録してください。'
              this.success = false;
              this.error = true;
              this.returnTop('smooth');
              return
            }
          }
        }
        this.updateUser();
      },
      delitem: function(){
        this.deleteUser();
        this.delDialog = false;
        this.dialogText = 'ユーザを無効にしました。';
        this.success = true;
      },
      //ユーザ情報設定
      setdata: function(user){
        this.id = user.id
        this.loginid = user.loginid
        if(this.id != 0){
          this.password = "********"
        } else {
          this.password = null
        }
        
        //ユーザ権限が追加された場合は要修正
        if(user.roletype === 0){
          this.radioGroup = user.roletype
        } else {
          this.radioGroup = 1
        }
        
        this.mailaddress = user.mailaddress
        
        this.delflg = user.delflg
        
        //センサグループ
        this.getUserGroup(user.loginid)
        
      },
      //ユーザセンサグループ削除更新
      updateUserSensorGroup(sensorgroupList,status){
        //lambda関数URL

        var apiurl = this.hosturl + "/user_sensor_group/" + this.loginid
        if(this.$store.state.user.username === this.loginid){
          var grouplist = this.$store.state.sensorgroup;
        }
        
        switch(status){
          case "PUT":
            for(var i=0; i<sensorgroupList.length; i++){
              var param = {
                "sensorgroupid": sensorgroupList[i].sensorgroupid
              }
              this.$axios.put(apiurl, param).then(res => {
                console.log(res)
              })
              .catch((err) => {
                console.log(err);
                this.dialogText = 'センサグループの設定に失敗しました。';
                this.success = false;
                this.error = true;
                this.returnTop('auto');
              });
              if(this.$store.state.user.username === this.loginid){
                grouplist.push(sensorgroupList[i].sensorgroupid)
              }
            }
            break;
          case "DELETE":
            for(var j=0; j<sensorgroupList.length; j++){
              this.$axios.delete(apiurl + "/?sensorgroupid=" + sensorgroupList[j].sensorgroupid).then(res => {
                console.log(res)
              })
              .catch((err) => {
                console.log(err);
                this.dialogText = 'センサグループの設定に失敗しました。';
                this.success = false;
                this.error = true;
                this.returnTop('auto');
              });
              if(this.$store.state.user.username === this.loginid){
                var newgrouplist = grouplist.filter(item => (item===sensorgroupList[j].sensorgroupid) == false);
                grouplist = newgrouplist;
              }
            }
            break;
        }
        if(this.$store.state.user.username === this.loginid){
          console.log('UPDATE:userSensorGroup')
          this.$store.commit('setSensorGroup', grouplist);
        }
        
        this.usersensorgrouplist[this.loginid] = this.selectgroup
        
      },
      //ユーザ情報登録・更新
      updateUser:function(){
        if(this.$refs.form.validate()){
          //lambda関数URL
          var apiurl = this.hosturl + "/users/" + this.loginid
          //PUT用パラメータ
          var param = []
          
          var setStatus = "";
          if(this.id === 0){
            if(signUp(this.loginid, this.password, this.mailaddress)){
              param = {
                "mailaddress": this.mailaddress,
                "role": this.radioGroup,
                "delflg": 0
              }
              setStatus = "ADD"
              console.log('PUT')
            }
          } else {
            param = {
              "mailaddress": this.mailaddress,
              "role": this.radioGroup,
              "delflg": 0
            }
            setStatus = "UPDATE"
            console.log('UPDATE')
          }
          // センサグループ比較
          this.compareSensorgroup(setStatus);
          this.active = []; //新規登録時にエラーメッセージが意図しないタイミングで表示される事象の対応
          this.$axios.put(apiurl, param).then(res => {
            console.log(res)
            // this.updateUserSensorGroup(this.$axios)
            this.getUser()
            this.dialogText = 'ユーザ情報の設定が完了しました。';
            this.success = true;
            this.error = false;
            this.returnTop('auto');
          })
          .catch((err) => {
            console.log(err);
            this.dialogText = 'ユーザ情報の設定に失敗しました。'
            this.success = false;
            this.error = true;
            this.returnTop('smooth');
            return
          });
          
        } else {
          this.dialogText = '不正な値が入力されています。入力内容を確認してください。'
          this.success = false;
          this.error = true;
          this.returnTop('smooth');
          return
        }
      },
      //ユーザ情報削除
      deleteUser: function(){
        var updDelflg = 1
        if( this.delflg === 1 ){
          updDelflg = 0
        }
        
        //lambda関数URL
        var apiurl = this.hosturl + "/users/" + this.loginid + "?delflg=" + updDelflg
        console.log(updDelflg)
        console.log('DELETE')

        this.$axios.delete(apiurl).then(res => {
          console.log(res)
          console.log(this.$store.state.user.username)
          console.log(this.loginid)
          if(this.$store.state.user.username === this.loginid){
            console.log('ログインユーザ無効化')
            this.$store.commit('setUser', null);
            this.$store.commit('setSensorGroup', []);
            this.$store.commit('setLoginUser', null);
            this.$store.commit('setDevice', null);
            alert('ログインユーザを無効にしました。ログアウトします。')
            var res2 = globalSignOut()
            console.log(res2)
          } else {
            
            this.getUser()
            if(this.delflg === 1){
              this.dialogText = 'ユーザ情報の有効化が完了しました。';
            } else {
              this.dialogText = 'ユーザ情報の無効化が完了しました。';
            }
            this.success = true;
            this.error = false;
            this.returnTop('auto');
          }
        })
        .catch((err) => {
          console.log(err);
          if(this.delflg === 1){
            this.dialogText = 'ユーザ情報の有効化に失敗しました。';
          } else {
            this.dialogText = 'ユーザ情報の無効化に失敗しました。';
          }
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      //ユーザ情報取得
      getUser:function(){
        //lambda関数URL
        var loginid = "scanlist"
        var apiurl = this.hosturl + "/users"
        
        var newItems = new Array();
        newItems.push({
          id:0,
          name:'新規作成',
          loginid:null,
          password:null,
          roletype:2,
          mailaddress:null
        });
        var cnt = 1;
            
        this.$axios.get(apiurl).then(res => {
          this.items = new Array();
          this.users = new Array();
          
          this.users = res.data[loginid];
          //表示用に加工
          
          this.users.forEach(function( value ){
            //if(value['delflg'] === 1){
            //  return;
            //}
            value['id'] = cnt;
            value['name'] = value['loginid'];
            newItems.push(value);
            cnt = cnt + 1;
          });
          
          this.items = newItems;
          this.items.sort(function(a, b){
            if(a.id < b.id){
              return -1;
            } else {
              return 1;
            }
          });
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'ユーザ情報の取得に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
        
        //選択状態初期化
        this.active = [];
        //ボタン状態初期化
        this.id = null;
        this.loginid = null;
        this.password = null;
      },
      //ユーザセンサグループ取得
      getUserGroup:function(loginid){
        if(this.usersensorgrouplist[loginid]){
          console.log(loginid + ' :exist')
          this.selectgroup = this.usersensorgrouplist[loginid]
          this.selectOldGroup = this.usersensorgrouplist[loginid]
          return
        }
        //lambda関数URL
        var apiurl = this.hosturl + "/user_sensor_group/" + loginid
        this.$axios.get(apiurl).then(res => {
          if(res){
            var grouplist = []
            res.data[loginid]['sensorgroupid'].forEach(function( value ){
              var groupitem = 
              {
                sensorgroupid: value,
                sensorgroup_name: value
              }
              grouplist.push(groupitem)
            });
            this.usersensorgrouplist[loginid] = grouplist
            this.selectgroup = this.usersensorgrouplist[loginid]
            this.selectOldGroup = this.usersensorgrouplist[loginid]
          }else{
            this.selectgroup = []
            this.selectOldGroup = []
          }
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'センサグループの取得に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      //センサグループ取得
      getSensorGroup:function(){
        //lambda関数URL
        var apiurl = this.hosturl + "/sensor_group/"
        this.$axios.get(apiurl).then(res => {
          if(res){
            this.sensorgroup = res['data']
          }
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'センサグループ情報の取得に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      // センサグループ比較
      compareSensorgroup:function(status){
        if(status == "ADD"){
          // 新規登録 PUT
          if(this.selectgroup.length > 0){
            this.updateUserSensorGroup(this.selectgroup,"PUT");
          }
        }
        else if(status == "UPDATE"){
          // 更新 PUT
          var putSensorgroup = this.selectgroup.filter(i => this.selectOldGroup.indexOf(i) == -1)
          if(putSensorgroup.length > 0){
            this.updateUserSensorGroup(putSensorgroup,"PUT");
          }
          // 更新 DELETE
          var deleteSensorgroup = this.selectOldGroup.filter(i => this.selectgroup.indexOf(i) == -1)
          if(deleteSensorgroup.length > 0){
            this.updateUserSensorGroup(deleteSensorgroup,"DELETE");
          }
        }
        this.selectOldGroup = this.selectgroup;
      },
      returnTop(action) {
        window.scrollTo({
          top: 0,
          behavior: action
        })
      },
    },
    mounted() {
      this.getUser();
      this.getSensorGroup();
    },
    watch: {
      //設定ボタン
      loginid: function (newValue) {
        if(newValue && this.password){
          this.isSet = false;
        } else {
          this.isSet = true;
        }
      },
      password: function (newValue) {
        if(newValue && this.loginid){
          this.isSet = false;
        } else {
          this.isSet = true;
        }
      },
      //削除ボタン
      id: function (newValue) {
        if(newValue){
          if(newValue === 0){
            this.isSetDel = true;
          } else {
            this.isSetDel = false;
          }
        } else {
          this.isSetDel = true;
        }
      }
    }
  }
</script>
<style scoped>
.v-data-table td {
    background: #2f1fbd;
}
</style>